exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-awards-index-js": () => import("./../../../src/pages/about/awards/index.js" /* webpackChunkName: "component---src-pages-about-awards-index-js" */),
  "component---src-pages-about-references-index-js": () => import("./../../../src/pages/about/references/index.js" /* webpackChunkName: "component---src-pages-about-references-index-js" */),
  "component---src-pages-about-sunesis-index-js": () => import("./../../../src/pages/about/sunesis/index.js" /* webpackChunkName: "component---src-pages-about-sunesis-index-js" */),
  "component---src-pages-accountdelete-index-js": () => import("./../../../src/pages/accountdelete/index.js" /* webpackChunkName: "component---src-pages-accountdelete-index-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-edison-index-js": () => import("./../../../src/pages/edison/index.js" /* webpackChunkName: "component---src-pages-edison-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-optimizacija-procesov-index-js": () => import("./../../../src/pages/projects/optimizacija-procesov/index.js" /* webpackChunkName: "component---src-pages-projects-optimizacija-procesov-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-technologies-index-js": () => import("./../../../src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-thanks-index-js": () => import("./../../../src/pages/thanks/index.js" /* webpackChunkName: "component---src-pages-thanks-index-js" */)
}

